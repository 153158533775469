@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
* {
  font-family: jost;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
$primary-color: #001a5f;
$black-color: #1c1c1c;
$white-color: #ffffff;

h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
}
h2 {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.6rem;
  text-transform: uppercase;
}
h3 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
}
h4 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
}
h5 {
  font-size: 5rem;
  line-height: 5rem;
}
h6 {
  font-size: 4rem;
  line-height: 4rem;
  color: $white-color;
}
p {
  font-size: 1.6rem;
}
a,
li,
.link {
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
  list-style: none;
  color: $white-color;
}
button {
  font-size: 1.6rem;
  padding: 1.3rem 3.5rem;
  outline: none;
  border: none;
  background: $primary-color;
  color: $white-color;
  border-radius: 1rem;
  transition: transform 0.2s ease;
  text-shadow: 0.2rem 0.2rem 0.4rem black;
  &:hover {
    transform: scale3d(1.01, 1.01, 1.01);
  }
}
@media only screen and (min-width: 776px) {
  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  h2 {
    font-weight: 500;
    font-size: 3rem;
  }
  h3 {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
  h4 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  h5 {
    font-size: 5.6rem;
    line-height: 5.6rem;
  }
  h6 {
    font-size: 4.4rem;
    line-height: 4.4rem;
  }
  p {
    font-size: 1.8rem;
  }
  a,
  li,
  .link {
    font-size: 1.8rem;
  }
  button {
    font-size: 1.8rem;
  }
}
.title {
  font-weight: 800;
  color: #0000001a;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: pre;
}
#root {
  overflow-x: hidden;
  width: 100%;
}
.logo {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 15rem;
    height: 19.5rem;
    object-fit: contain;
  }
}
footer {
  background: #d4d5cf;
  width: 100%;
  position: relative;
  padding: 0 3rem;
  padding-top: 25rem;
  article {
    margin: 0 auto;
    // min-width: 20rem;
    max-width: 110rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    section {
      display: block;
      max-width: 40rem;
      div {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        img {
          width: 1.6rem;
          height: 1.6rem;
          display: inline;
        }
        a {
          margin-left: 0.5rem;
          display: inline;
          font-size: 1.6rem;
          line-height: 1.8rem;
          color: $black-color;
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
  }

  .copyright {
    margin: 0 auto;
    max-width: 110rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2rem;
    a {
      color: $black-color;
    }
  }
  @media only screen and (min-width: 776px) {
    article {
      gap: 3rem;
      justify-content: space-between;
    }
    .copyright {
      gap: 3rem;
      justify-content: space-between;
    }
  }
}
.menu-icon {
  position: absolute;
  top: 3rem;
  right: 3rem;
  background: #00000073;
  padding: 0.8rem;
  border-radius: 1rem;
  width: 4rem;
  z-index: 9;
  height: 4rem;
  &:before,
  &:after,
  & span {
    background: #fff;
    content: "";
    display: block;
    height: 0.3rem;
    border-radius: 0.3rem;
    margin: 0.4rem 0;
    transition: 0.5s;
  }
}
.active:before {
  transform: translateY(0.7rem) rotate(135deg);
}
.active:after {
  transform: translateY(-0.7rem) rotate(-135deg);
}
.active span {
  transform: scale(0);
}
header {
  ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    li {
      white-space: pre;
      text-align: end;
      line-height: 4rem;
      cursor: pointer;
      &::after {
        content: "";
        display: inline-block;
        width: 2rem;
        height: 0.2rem;
        vertical-align: middle;
        background-color: white;
        margin-left: 1rem;
        margin-bottom: 0.4rem;
      }
      transition: font-size 0.2s ease;
      &:hover {
        font-size: 2rem;
      }
    }
  }
  position: absolute;
  top: 30rem;
  opacity: 0;
  z-index: 99;
  right: -40rem;
  display: none;
  transition: right 0.2s ease;
}
.hero-bar-active {
  opacity: 1;
  right: 0rem;
  display: block;
}
@media only screen and (min-width: 776px) {
  .menu-icon {
    display: none;
  }
  header {
    opacity: 1;
    right: 0;
    display: block;
    transition: none;
  }
}
.dot {
  width: 16px !important;
  height: 16px !important;
}
.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 8rem;

  &-text {
    margin: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: auto;
    h6 {
      text-align: start;
      min-width: 31.9rem;
      max-width: 80%;
    }
  }
}
.whoweare {
  position: relative;
  margin-top: 5rem;
  padding: 3rem 2.6rem;
  display: flex;
  flex-direction: column;
  &-first {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    aside {
      max-width: 60rem;
      p {
        width: 100%;
        margin-top: 2rem;
        text-align: justify;
      }
    }
    img {
      width: 38rem;
      height: 38rem;
    }
    // @media only screen and (min-width: 1000px) {
    //   justify-content: space-between;
    // }
  }
  .statements {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    &-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 35rem;
      &-text {
        box-shadow: inset 0.8rem 0.8rem 2rem #ffffff33,
          inset -0.8rem -0.8rem 3rem #00000033;
        background: #ebebeb;
        height: 31rem;
        padding: 1rem 2rem;
        border-radius: 1.2rem;
        h2 {
          background: #919191;
          color: $white-color;
          white-space: pre;
          border-radius: 0.8rem;
          padding: 1rem;
        }
        p {
          text-align: justify;
          margin-top: 1rem;
          width: 100%;
        }
      }
      img {
        margin-top: -6rem;
        width: 12rem;
        height: 12rem;
      }
    }
  }
  .count {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    &-counter {
      width: 16.9rem;
      height: 14.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      h4 {
        color: $primary-color;
      }
    }
  }
}
.article {
  padding: 3rem 2.6rem;
  display: block;
  margin: 0 auto;
  max-width: 80rem;
  p {
    margin-top: 2rem;
  }
}
.whatwedo {
  position: relative;
  margin-top: 5rem;
  .article-2 {
    margin: 0 auto;
    max-width: 110rem;
    padding: 3rem 2.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    .subQ {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 13.5rem;
      height: 12rem;
      gap: 1.4rem;
      h4 {
        text-align: center;
        color: $primary-color;
      }
    }
  }
  .article-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
    max-width: 80rem;
    .quality {
      background: $primary-color;
      width: 15rem;
      height: 15rem;

      padding: 1.3rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 1.6rem;
      gap: 2rem;
      img {
        width: 5rem;
        height: 5rem;

        object-fit: contain;
      }
      h3 {
        font-size: 1.6rem;
        text-align: center;
        color: $white-color;
      }
    }
  }
  @media only screen and (min-width: 1100px) {
    .article-3 {
      max-width: 100rem;
      .quality {
        width: 20rem;
        height: 20rem;
        img {
          width: 8rem;
          height: 8rem;
        }
        h3 {
          font-size: 2rem;
        }
      }
    }
  }
}
.ind {
  position: relative;
  margin-top: 5rem;
  .article-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
    max-width: 110rem;
    section {
      background: #ebebeb;
      padding: 2rem;
      display: block;
      max-width: 32rem;
      border-radius: 2rem;
      .section-image {
        width: 100%;
        height: 23rem;
        object-fit: cover;
        border-radius: 1rem;
      }
      h2 {
        margin-top: 2.8rem;
      }
      a {
        float: right;
        display: block;
        margin-top: 2.3rem;
        color: $black-color;
      }
    }
  }
}
.bookmeet {
  margin-top: 6rem;
  padding: 5rem 0;
  width: 100%;
  background: linear-gradient(to bottom, #ffffffa4, #ffffffc7),
    center/cover no-repeat url(./assets/content/contact.png);
  p {
    display: inline-block;
  }
  ol {
    margin-top: 2rem;
    padding-left: 2rem;
  }
  li {
    color: $black-color;
    list-style: decimal;
    line-height: 1.8rem;
  }
  button {
    display: block;
    margin: 0 auto;
  }
}
.banner {
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 2rem;
  width: 100%;
  height: 90vh;
  background: linear-gradient(to bottom, #000000a4, #000000c7),
    center/cover no-repeat url(./assets/content/contact.png);
  h6 {
    text-transform: uppercase;
  }
}

form {
  display: block;
  margin: 4rem auto;
  width: 80%;
  max-width: 100rem;
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }
  .image-checkbox {
    display: flex;
    border-radius: 0.8rem;
    flex-direction: column;
    background: #001a5f;
    width: 10rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;
    position: relative;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 6rem;
      height: 6rem;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;
    }
    &-text {
      padding: 0 0.4rem;
      color: $white-color;
      text-align: center;
      opacity: 0.5;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
    input[type="checkbox"] {
      display: none;
    }
    .tick {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1rem;
      background: #bbbbbb;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.6rem;
        height: 1.6rem;
        opacity: 0;
      }
    }
  }
  .image-checkbox input[type="checkbox"]:checked ~ .icon,
  .image-checkbox input[type="checkbox"]:checked ~ .image-checkbox-text {
    opacity: 1;
  }
  .image-checkbox input[type="checkbox"]:checked ~ .tick img {
    opacity: 1;
  }
}
.form-container {
  margin: 5rem auto;
  width: 100%;
  display: grid;
  gap: 1rem;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 776px) {
  .banner {
    h6 {
      font-size: 8rem;
    }
  }
  .form-container {
    grid-template-columns: 1fr 1fr;
  }
  .fullwidth {
    width: 100%;
    grid-column: span 2;
  }
  .width-16 {
    justify-self: flex-end;
    width: 16rem;
  }
}
input[type="text"] {
  width: 100%;
  height: 4.1rem;
  border-radius: 0.8rem;
  outline: none;
  border: 0.2rem solid #00000034;
  font-size: 1.2rem;
  padding-left: 1rem;
}
input[type="file"] {
  display: block;
  font-size: 1.6rem;
}
label {
  font-size: 1.2rem;
}
textarea {
  width: 100%;
  border: 0.2rem solid #00000034;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-left: 1rem;
  border-radius: 0.8rem;
}
.industries {
  display: block;
  margin: 8rem auto;
  width: 80%;
  max-width: 120rem;
  h2 {
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and (min-width: 776px) {
    .flex-center {
      flex-direction: row;
    }
  }
  article {
    img {
      width: 100%;
      max-width: 36rem;
      height: 100%;
      max-height: 26rem;
      object-fit: contain;
    }
  }
  .small-image {
    width: 16rem;
    height: 16rem;
  }
}
.submit-form {
  margin: 4rem auto;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  img {
    width: 6rem;
  }
  h4 {
    text-align: center;
  }
}
.mono-text {
  font-size: 1.2rem;
}
